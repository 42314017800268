
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "kt-notifications",
  components: {},
  setup() {
    const list = ref([
      {
        color: "warning",
        icon: "media/icons/duotune/abstract/abs027.svg",
        title: "Group lunch celebration",
        text: "Due in 2 Days",
        number: "+28%",
      },
      {
        color: "primary",
        icon: "media/icons/duotune/files/fil008.svg",
        title: "Navigation optimization",
        text: "Due in 2 Days",
        number: "+50%",
      },
      {
        color: "danger",
        icon: "media/icons/duotune/communication/com012.svg",
        title: "Humbert Bresnen",
        text: "Due in 5 Days",
        number: "-27%",
      },
      {
        color: "info",
        icon: "media/icons/duotune/finance/fin006.svg",
        title: "Air B & B - Real Estate",
        text: "Due in 8 Days",
        number: "+21%",
      },
      {
        color: "primary",
        icon: "media/icons/duotune/arrows/arr031.svg",
        title: "B & Q - Food Company",
        text: "Due in 6 Days",
        number: "+12%",
      },
      {
        color: "danger",
        icon: "media/icons/duotune/art/art005.svg",
        title: "Nexa - Next generation",
        text: "Due in 4 Days",
        number: "+34%",
      },
    ]);

    return {
      list,
    };
  },
});
