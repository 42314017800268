
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const webURL = process.env.VUE_APP_WEB_URL;
    const appName = process.env.VUE_APP_NAME;
    return {
      footerWidthFluid,
      webURL,
      appName,
    };
  },
});
